<template>
  <div class="modal fade " :id="modalName" tabindex="-1" role="dialog" :aria-labelledby="modalName" aria-hidden="true">
    <div class="modal-dialog  modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Update Address Information</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Address</label>
                <small v-if="!addressInformationSelectDiv && hasUserAddresses"
                       @click="addressInformationSelectDiv = !addressInformationSelectDiv" class="text-primary">&nbsp;(Click
                  to select address from existing one)</small>
                <small v-if="addressInformationSelectDiv"
                       @click="addressInformationSelectDiv = !addressInformationSelectDiv" class="text-primary">&nbsp;(Click
                  to create a new address)</small>
                <VueMultiselect v-if="addressInformationSelectDiv" v-model="selectedAddress" :options="userAddresses"
                                :close-on-select="true" placeholder="Select Address" label="name" track-by="value"
                                :show-labels="false" :allow-empty="false"/>
                <vue-google-autocomplete v-else :enable-geolocation="true" ref="address" :country="['au']" id="map"
                                         classname="form-control" placeholder="Please type full address."
                                         v-on:placechanged="googleMapApiResponseData"/>
                <div class="text-danger" v-if="errors.userId">{{ errors.userId }}</div>
                <div class="text-danger" v-if="errors.addressId">{{ errors.addressId }}</div>
              </div>
            </div>
          </div>
          <div v-if="!addressInformationSelectDiv && hasAddressData" class="row">
            <div class="col-12 d-flex flex-row bx-flashing text-warning">
              <i class='bx bx-info-circle' style="line-height: 1.5rem !important;"></i>
              <span>&nbsp;Please modify the following address fields, if there anything wrong!</span>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Flat/Unit number</label>
                <input v-model="postAddressData.subpremise" type="text" class="form-control" placeholder="Enter flat/unit number" />
                <div class="text-danger" v-if="errors.subpremise">{{ errors.subpremise }}</div>
                <div class="text-danger" v-if="errors.addressId">{{ errors.addressId }}</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Street Address *</label>
                <input v-model="postAddressData.street" type="text" class="form-control"
                       placeholder="Enter street address"/>
                <div class="text-danger" v-if="errors.street">{{ errors.street }}</div>
                <div class="text-danger" v-if="errors.addressId">{{ errors.addressId }}</div>
              </div>
            </div>

            <div class="col-12 col-md-3">
              <div class="form-group">
                <label>Suburbs/ Territory *</label>
                <input v-model="postAddressData.suburb" type="text" class="form-control"
                       placeholder="Enter suburbs/ territory"/>
                <div class="text-danger" v-if="errors.suburb">{{ errors.suburb }}</div>
              </div>
            </div>

            <div class="col-12 col-md-3">
              <div class="form-group">
                <label>State *</label>
                <VueMultiselect v-model="selectedState" :allowEmpty="false" :options="states" :close-on-select="true"
                                placeholder="Select state" label="name" track-by="value" :show-labels="false"/>
                <div class="text-danger" v-if="errors.state">{{ errors.state }}</div>
              </div>
            </div>

            <div class="col-12 col-md-3">
              <div class="form-group">
                <label>Post Code *</label>
                <input v-model="postAddressData.post_code" type="text" class="form-control" placeholder="Enter code"
                       required/>
                <div class="text-danger" v-if="errors.postCode">{{ errors.postCode }}</div>
              </div>
            </div>

            <div class="col-12 col-md-3">
              <div class="form-group">
                <label>Country *</label>
                <input v-model="postAddressData.country" type="text" class="form-control" readonly required/>
                <div class="text-danger" v-if="errors.country">{{ errors.country }}</div>
              </div>
            </div>
          </div>
          <div v-if="!addressInformationSelectDiv && businessStatus" class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Business Name *</label>
                <input v-model="postBusinessData.name" type="text" class="form-control"/>
                <div class="text-danger" v-if="errors.business.name">{{ errors.business.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button v-if="addressInformationSelectDiv" class="btn btn-light-primary ml-1"
                  :disabled="!selectedAddress.value" @click="updateModelAddress(selectedAddress.value)">Save Change
          </button>
          <button v-else class="btn btn-light-primary ml-1"
                  :disabled="!hasAddressData || (businessStatus && postBusinessData.name ==='')"
                  @click="createUserAddressAndAssign">Create & Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";


// core packages
import {mapGetters, mapActions} from 'vuex';

// packages
import VueMultiselect from 'vue-multiselect';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import AddressMixin from "@/components/backEnd/mixins/AddressMixin";
import GlobalMixin from "@/components/backEnd/mixins/GlobalMixin";

export default {
  name: "AddressUpdateModal",
  mixins: [ShowToastMessage, Loader, AddressMixin, GlobalMixin],
  components: {
    VueMultiselect,
    VueGoogleAutocomplete
  },
  props: {
    modalName: {
      type: String,
      default: "addressUpdateModal",
      required: true,
    },
    addressUpdateForModelName: {
      type: String,
      required: true,
    },
    modelId: {
      type: [Number, String],
      required: true
    },
    userId: {
      type: [Number, String],
      required: true,
    },
    selectedAddressData: {
      type: Object,
      required: false,
    },
    businessStatus: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      addressInformationSelectDiv: false,
      hasUserAddresses: false,

      postAddressData: {
        user_id: '',
        subpremise: '',
        street: '',
        suburb: '',
        state: '',
        post_code: '',
        country: '',
      },

      selectedState: {
        value: '',
        name: 'None',
      },

      selectedAddress: {
        value: '',
        name: 'None'
      },

      postBusinessData: {
        user_id: '',
        address_id: '',
        name: '',
      },

      errors: {
        userId: '',
        subpremise: '',
        street: '',
        suburb: '',
        state: '',
        postCode: '',
        country: '',
        business: {
          userId: '',
          addressId: '',
          name: '',
        },
      },

      getSettingsParams: {
        type: ['default'],
        key: ['default_state'],
      },
    }
  },

  computed: {
    ...mapGetters({
      addresses: 'appAddresses/addresses',
      admin: 'appAdmins/admin',
      customer: 'appCustomers/customer',
      employee: 'appEmployees/employee',
      partner: 'appPartners/partner',
      vendor: 'appVendors/vendor',
      labUser: 'appLabUsers/labUser',
    }),

    states() {
      return this.$store.getters['appSettings/settingDefaultState'].value
    },

    userAddresses() {
      let userAddresses = this.addresses.length > 0 ? this.businessStatus ?
          this.addresses.filter((address) => address.business && address.business.id).map((address) => {
            let businessName = address.business && address.business.name ? address.business.name : '';
            let addressId = address.id;

            return {
              value: addressId,
              name: `${businessName}`.concat(this.buildAddress(address)),
            };
          }) :
          this.addresses.map((address) => {
            let addressId = address.id;

            return {
              value: addressId,
              name: this.buildAddress(address),
            };
          }) : [];

      return [
        {
          value: '',
          name: 'None'
        },
        ...userAddresses
      ];
    },

    hasAddressData() {
      return !!(this.postAddressData.street || this.postAddressData.suburb || this.postAddressData.state || this.postAddressData.post_code);
    },
  },
  watch: {
    userId() {
      this.getUserAddresses();
      this.postAddressData.user_id = this.userId;
      this.postBusinessData.user_id = this.userId;
    },

    selectedAddressData(selectedAddressData) {
      this.selectedAddress.value = selectedAddressData.value;
      this.selectedAddress.name = selectedAddressData.name;
    },

    selectedState(selectedState) {
      this.postAddressData.state = selectedState.value;
    },
  },
  methods: {
    ...mapActions({
      getAddresses: 'appAddresses/getAddresses',
      getSettings: 'appSettings/getSettings',
      postAddress: 'appAddresses/postAddress',
      postBusiness: 'appBusinesses/postBusiness',
      putAdmin: 'appAdmins/putAdmin',
      putVendor: 'appVendors/putVendor',
      putCustomer: 'appCustomers/putCustomer',
      putEmployee: 'appEmployees/putEmployee',
      putLabUser: 'appLabUsers/putLabUser',

    }),
    async closeModal() {
      document.querySelector(`[data-target="#${this.modalName}"]`).click(); // modal close
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getUserAddresses() {
      let paramObj = {
        user_id: this.userId,
      };

      if (this.businessStatus) {
        paramObj.with_relation = ['business'];
      }

      await this.getAddresses(paramObj).then(async (response) => {
        if (response.status === 200 && this.addresses.length > 0) {
          this.addressInformationSelectDiv = true;
          this.hasUserAddresses = true;
        }
      });
    },

    googleMapApiResponseData: function (googleMapApiResponseData) {
      this.loader(true);

      let subPremise = googleMapApiResponseData.subpremise !== undefined ? googleMapApiResponseData.subpremise : '';
      let streetNumber = googleMapApiResponseData.street_number !== undefined ? googleMapApiResponseData.street_number : '';
      let streetName = googleMapApiResponseData.route !== undefined ? googleMapApiResponseData.route : '';
      let suburbs = googleMapApiResponseData.locality !== undefined ? googleMapApiResponseData.locality : '';
      let state = googleMapApiResponseData.administrative_area_level_1 !== undefined ? googleMapApiResponseData.administrative_area_level_1 : '';
      let postCode = googleMapApiResponseData.postal_code !== undefined ? googleMapApiResponseData.postal_code : '';
      let country = googleMapApiResponseData.country !== undefined ? googleMapApiResponseData.country : '';

      this.postAddressData.subpremise = this.titleCase(subPremise);
      this.postAddressData.street = streetNumber ? `${streetNumber} ${streetName}` : streetName;
      this.postAddressData.suburb = suburbs;
      this.postAddressData.state = state.toLowerCase();
      this.postAddressData.post_code = postCode;
      this.postAddressData.country = country;

      if (state) {
        let addressStateIndex = this.states.findIndex(item => item.value === state.toLowerCase());
        if (addressStateIndex !== -1) {
          let addressState = this.states[addressStateIndex];

          this.selectedState.value = addressState.value;
          this.selectedState.name = addressState.name;
        }
      }

      this.loader(false);

    },

    async emitUpdateModalAddressData(addressDataObj) {
      this.$emit('updateModalAddressData', {
        addressData: addressDataObj,
      });
    },

    async updateModelAddress(addressId) {
      let dataObj = {
        id: this.modelId,
        data: {
          address_id: addressId,
        },
      };
      let successToastObj = {message: 'Primary Address Info Updated', type: 'success'};

      if (this.businessStatus) {
        successToastObj.message = 'Primary Business Address Info Updated';
      }

      if (this.addressUpdateForModelName === 'admin') {
        this.putAdmin(dataObj).then(async (response) => {
          if (response.status === 200) {
            await this.closeModal();
            this.showToastMessage(successToastObj);
            let addressDataObj = {
              id: addressId ?? null,
              userId: this.admin.user && this.admin.user.id ? this.admin.user.id : null,
              subpremise: this.admin.address && this.admin.address.subpremise ? this.admin.address.subpremise : '',
              street: this.admin.address && this.admin.address.street ? this.admin.address.street : '',
              suburb: this.admin.address && this.admin.address.suburb ? this.admin.address.suburb : '',
              state: this.admin.address && this.admin.address.state ? this.admin.address.state : '',
              postCode: this.admin.address && this.admin.address.post_code ? this.admin.address.post_code : '',
              country: this.admin.address && this.admin.address.country ? this.admin.address.country : '',
            };
            await this.emitUpdateModalAddressData(addressDataObj);
          }
          this.errors.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
          this.showToastMessage(response);
        });
      }

      if (this.addressUpdateForModelName === 'vendor') {
        this.putVendor(dataObj).then(async (response) => {
          if (response.status === 200) {
            await this.closeModal();
            this.showToastMessage(successToastObj);
            let addressDataObj = {
              id: addressId ?? null,
              userId: this.vendor.user && this.vendor.user.id ? this.vendor.user.id : null,
              subpremise: this.vendor.address && this.vendor.address.subpremise ? this.vendor.address.subpremise : '',
              street: this.vendor.address && this.vendor.address.street ? this.vendor.address.street : '',
              suburb: this.vendor.address && this.vendor.address.suburb ? this.vendor.address.suburb : '',
              state: this.vendor.address && this.vendor.address.state ? this.vendor.address.state : '',
              postCode: this.vendor.address && this.vendor.address.post_code ? this.vendor.address.post_code : '',
              country: this.vendor.address && this.vendor.address.country ? this.vendor.address.country : '',
            };
            if (this.businessStatus) {
              addressDataObj.business = {
                id: this.vendor?.address?.business?.id ?? null,
                name: this.vendor?.address?.business?.name ?? '',
              };
            }
            await this.emitUpdateModalAddressData(addressDataObj);
          }

          this.errors.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
          this.showToastMessage(response);
        });
      }

      if (this.addressUpdateForModelName === 'labUser') {
        this.putVendor(dataObj).then(async (response) => {
          if (response.status === 200) {
            await this.closeModal();
            this.showToastMessage(successToastObj);
            let addressDataObj = {
              id: addressId ?? null,
              userId: this.labUser.user && this.labUser.user.id ? this.labUser.user.id : null,
              subpremise: this.labUser.address && this.labUser.address.subpremise ? this.labUser.address.subpremise : '',
              street: this.labUser.address && this.labUser.address.street ? this.labUser.address.street : '',
              suburb: this.labUser.address && this.labUser.address.suburb ? this.labUser.address.suburb : '',
              state: this.labUser.address && this.labUser.address.state ? this.labUser.address.state : '',
              postCode: this.labUser.address && this.labUser.address.post_code ? this.labUser.address.post_code : '',
              country: this.labUser.address && this.labUser.address.country ? this.labUser.address.country : '',
            };
            await this.emitUpdateModalAddressData(addressDataObj);
          }

          this.errors.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
          this.showToastMessage(response);
        });
      }

      if (this.addressUpdateForModelName === 'customer') {
        this.putCustomer(dataObj).then(async (response) => {
          if (response.status === 200) {
            await this.closeModal();
            this.showToastMessage(successToastObj);
            let addressDataObj = {
              id: addressId ?? null,
              userId: this.customer.user && this.customer.user.id ? this.customer.user.id : null,
              subpremise: this.customer.address && this.customer.address.subpremise ? this.customer.address.subpremise : '',
              street: this.customer.address && this.customer.address.street ? this.customer.address.street : '',
              suburb: this.customer.address && this.customer.address.suburb ? this.customer.address.suburb : '',
              state: this.customer.address && this.customer.address.state ? this.customer.address.state : '',
              postCode: this.customer.address && this.customer.address.post_code ? this.customer.address.post_code : '',
              country: this.customer.address && this.customer.address.country ? this.customer.address.country : '',
            };

            if (this.businessStatus) {
              addressDataObj.business = {
                id: this.customer.address.business && this.customer.address.business.id ? this.customer.address.business.id : null,
                name: this.customer.address.business && this.customer.address.business.name ? this.customer.address.business.name : '',
              };
            }

            await this.emitUpdateModalAddressData(addressDataObj);
          }
          this.errors.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
          this.showToastMessage(response);
        });
      }

      if (this.addressUpdateForModelName === 'employee') {
        this.putEmployee(dataObj).then(async (response) => {
          if (response.status === 200) {
            await this.closeModal();
            this.showToastMessage(successToastObj);
            let addressDataObj = {
              id: addressId ?? null,
              userId: this.employee.user && this.employee.user.id ? this.employee.user.id : null,
              subpremise: this.employee.address && this.employee.address.subpremise ? this.employee.address.subpremise : '',
              street: this.employee.address && this.employee.address.street ? this.employee.address.street : '',
              suburb: this.employee.address && this.employee.address.suburb ? this.employee.address.suburb : '',
              state: this.employee.address && this.employee.address.state ? this.employee.address.state : '',
              postCode: this.employee.address && this.employee.address.post_code ? this.employee.address.post_code : '',
              country: this.employee.address && this.employee.address.country ? this.employee.address.country : '',
            };
            await this.emitUpdateModalAddressData(addressDataObj);
          }
          this.errors.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
          this.showToastMessage(response);
        });
      }

      if (this.addressUpdateForModelName === 'partner') {
        this.putEmployee(dataObj).then(async (response) => {
          if (response.status === 200) {
            await this.closeModal();
            this.showToastMessage(successToastObj);
            let addressDataObj = {
              id: addressId ?? null,
              userId: this.partner.user && this.partner.user.id ? this.partner.user.id : null,
              subpremise: this.partner.address && this.partner.address.subpremise ? this.partner.address.subpremise : '',
              street: this.partner.address && this.partner.address.street ? this.partner.address.street : '',
              suburb: this.partner.address && this.partner.address.suburb ? this.partner.address.suburb : '',
              state: this.partner.address && this.partner.address.state ? this.partner.address.state : '',
              postCode: this.partner.address && this.partner.address.post_code ? this.partner.address.post_code : '',
              country: this.partner.address && this.partner.address.country ? this.partner.address.country : '',
            };
            await this.emitUpdateModalAddressData(addressDataObj);
          }
          this.errors.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
          this.showToastMessage(response);
        });
      }

    },

    async createUserAddressAndAssign() {
      this.loader(true);
      await this.postAddress(this.postAddressData).then(async (response) => {
        if (response.status === 200 || response.status === 201) {
          let address = this.$store.getters['appAddresses/address'];
          this.postBusinessData.address_id = address.id;

          if (this.businessStatus) {
            await this.createSingleBusinessAddress();
            if (this.errors.business.name || this.errors.business.addressId || this.errors.business.userId) {
              this.loader(false);
              return;
            }
          }
          let business = this.$store.getters['appBusinesses/business'];

          let newAddress = this.businessStatus ? {
            value: address.id,
            name: `${business.name} ${(this.buildAddress(address))}`  ,
          } : {
            value: address.id,
            name: this.buildAddress(address)
          };

          this.userAddresses.push(newAddress);

          this.selectedAddress.value = newAddress.value;
          this.selectedAddress.name = newAddress.name;

          await this.updateModelAddress(address.id);

          this.hasUserAddresses = true;
          this.addressInformationSelectDiv = true;

        }
        this.errors.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.street = response.errors && response.errors.street ? response.errors.street[0] : '';
        this.errors.suburb = response.errors && response.errors.suburb ? response.errors.suburb[0] : '';
        this.errors.state = response.errors && response.errors.state ? response.errors.state[0] : '';
        this.errors.postCode = response.errors && response.errors.post_code ? response.errors.post_code[0] : '';
        this.errors.country = response.errors && response.errors.country ? response.errors.country[0] : '';

        this.showToastMessage(response);
      });
      this.loader(false);
    },

    async createSingleBusinessAddress() {
      await this.postBusiness(this.postBusinessData).then(async (response) => {

        this.errors.business.name = response.errors && response.errors.name ? response.errors.name[0] : '';
        this.errors.business.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
        this.errors.business.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },
  },

  async mounted() {
    await this.getSettingList();
  },
}
</script>
